import $ from "./../vendor/jquery";

function createAccordion(accordionListElement){
	return Object.create(accordionController).init(accordionListElement);
}

const accordionController = {
	init: init
};

function init(accordionListElement){
	this.listElement = accordionListElement;

	build.call(this);
	return this;
}

function build(){
	this.accordions = this.listElement.find('.js-accordion');
	this.listElement.on('click', '.js-accordion-button', handleAccodionButton.bind(this));
}

function handleAccodionButton(e){
	e.preventDefault();
	const target = $(e.target).closest('.js-accordion');
	if(!target.hasClass('accordion--open')){
		this.listElement.find('.accordion--open.js-accordion').find('.accordion__content').slideUp({
			always: function(){
				$(this).closest('.js-accordion').removeClass('accordion--open');
			}
		});
		target.find('.accordion__content').slideDown({
			always: function(){
				$(this).closest('.js-accordion').addClass('accordion--open');
			}
		});
	}else{
		this.listElement.find('.accordion--open.js-accordion').find('.accordion__content').slideUp({
			always: function(){
				$(this).closest('.js-accordion').removeClass('accordion--open');
			}
		});
	}
}

export default createAccordion;