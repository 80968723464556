import moment from "./../vendor/moment-timezone.js";

function createClock(clockElement, timezoneName){
	return Object.create(clockController).init(clockElement, timezoneName);
}

const clockController = {
	init: init,
	clockUpdate: clockUpdate
};

function init(clockElement, timezoneName){
	this.clockElement = clockElement;
	this.timezoneName = timezoneName;

	if(this.clockElement.data('timezone')){
		this.timezoneName = this.clockElement.data('timezone');
	}

	this.minHand = this.clockElement.find('#minHand').get(0);
	this.hourHand = this.clockElement.find('#hourHand').get(0);

	startClock.call(this);
}

function startClock(){
	if(this.clockElement.length){
		this.clockInterval = setInterval(this.clockUpdate.bind(this), 1000);
	}
}

function clockUpdate(){
	const date = getDateObj.call(this);
	this.minHand.setAttribute('transform', 'rotate('+ (6*date.minutes()) +' 60 60)');
	this.hourHand.setAttribute('transform', 'rotate('+ (30*(date.hours()%12) + date.minutes()/2) +' 60 60)');
}

function getDateObj(){
	let date = moment();
	if(this.timezoneName){
		date = date.tz(this.timezoneName);
	}
	return date;
}

export default createClock;