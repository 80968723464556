import $ from "./../vendor/jquery";
import createAccordion from "./accordion";
import createClock from "./clock";
import "./../vendor/rdslick";
import "./../vendor/mmenu.min";

function init(){
	setupAccordionLists();
	setupClocks();
	setupSlider();
	setupHomeSlider();
	setupMenu();
	setupModals();
}

function setupAccordionLists(){
	const accordionLists = $(".js-accordion-list");
	accordionLists.each(function(i, el){
		el.accordionController = createAccordion($(el));
	});
}

function setupClocks(){
	const clocks = $(".js-clock");
	clocks.each(function(i, el){
		el.clockController = createClock($(el));
	});
}


function setupSlider(){
	const slider = $(".js-slider");
	slider.rdslick({
		cssEase: 'cubic-bezier(0.3, 0.15, 0.18, 1)',
		arrows: false,
		dots: true
	});
}

function setupHomeSlider(){
	const slider = $(".js-home-slider");
	slider.rdslick({
		cssEase: 'cubic-bezier(0.3, 0.15, 0.18, 1)',
		arrows: true,
		dots: true,
		prevArrow: '<button class="slick-prev"><div><svg><use xlink:href="#slider-left-arrow" /></svg></div></button>',
		nextArrow: '<button class="slick-next"><div><svg><use xlink:href="#slider-right-arrow" /></svg></div></button>'
	});
}

function setupMenu(){
	const nav = $(".js-primary-nav");

	nav.mmenu({
		offCanvas: {
			position: "left",
			zposition: "front"
		}
	});

	nav.find('.mm-navbar').append('<button class="primary-nav__mobile-btn-close js-primary-nav-btn-close">x</button>');

	const navBtn = $(".js-primary-nav-btn");
	navBtn.on('click', function(){
		nav.data('mmenu').open();
	});

	const navBtnBlose = $(".js-primary-nav-btn-close");
	navBtnBlose.on('click', function(){
		nav.data('mmenu').close();
	});
}

function setupModals(){
	const openLoginBtn = $('.js-login-open');
	const loginModal = $('.js-modal-login');
	openLoginBtn.on('click', function(e){
		e.preventDefault();
		loginModal.addClass('j-modal--open');
	});

	loginModal.on('click', '.js-modal-close', function(){
		loginModal.removeClass('j-modal--open');
	});
}

$(document).ready(init);